import { useStaticQuery, graphql } from "gatsby";
import resolveArticle from "@dataResolvers/resolveArticle";

const query = graphql`
  query PublicationsQuery {
    craft {
      entries(section: "articles", articleCategory: 3549) {
        ... on Craft_articles_external_Entry {
          url
          title
          articleCategory {
            uid
            title
          }
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          postDate
        }
        ... on Craft_articles_default_Entry {
          url
          title
          articleCategory {
            uid
            title
          }
          #articleAuthor {
          #  title
          #}
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          postDate
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  return entries?.map(resolveArticle);
};

const useArticlesData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useArticlesData;
