import React from "react";
import usePublicationsData from "@staticQueries/PublicationsQuery";
import { AppLink } from "@base";
import { Image, Icon, Container } from "@atoms";

const RecentPublications = ({ entriesOverride }) => {
  const recentPublications = usePublicationsData();
  const publications = entriesOverride.length
    ? entriesOverride
    : recentPublications;
  return (
    <section className="my-10 sm:my-20">
      <Container padding>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-12 lg:gap-x-20">
          {publications.map(p => (
            <AppLink
              to={p.url}
              className="group flex w-full flex-row items-center gap-2 sm:items-start"
            >
              {p.image?.url && (
                <Image
                  className="!w-20 flex-shrink-0 shadow-lg lg:w-24 xxl:w-32"
                  image={p.image}
                  ixParams={{ fit: "crop" }}
                  resolution={3}
                  aspectRatio={[8.5, 11]}
                />
              )}
              {!p.image?.url && (
                <span className="relative block aspect-[8.5/11] w-20 flex-shrink-0 bg-white shadow-lg lg:w-24 xxl:w-32">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Icon
                      name="academicArticle"
                      className="h-auto w-1/4 text-gray"
                    />
                  </div>
                </span>
              )}
              <div className="pl-2">
                <span className="mt-2 block text-sm font-bold leading-tight text-slate xxl:text-base">
                  {p.title}
                </span>
                <span className="mt-2 block text-xs font-bold text-pink underline underline-offset-2">
                  View Publication
                </span>
              </div>
            </AppLink>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default RecentPublications;
